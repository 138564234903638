.service-list ul {
    text-align: left;
    padding-left: 20px; /* Optional: Add some left padding for indentation */
  }
.custom-accordion .accordion-item {
    background-color: #EEE2DC; /* Replace with your desired background color */
    color: #646D74;
  }

  .custom-accordion .accordion-button{
    background-color: #BAB2B5;
    font-weight: bold;
    color: #646D74;
}

.card-body{
  background-color: #EEE2DC; /* Replace with your desired background color */
  color: #646D74;
}

.reviews-container .card-text{
  color: #646D74;
}

.reviews-container .card {
  border: 2px solid black; /* Adjust the border thickness as needed */
}

.reviews-container {
  display: grid;
  grid-gap: 20px; /* Adjust the value as per your desired spacing */
}

.name-row{
  color: #646D74;
}

.btn.btn-primary {
  margin-top: 10px;
  margin-right: 10px; /* Adds 10 pixels of right margin */
  padding: 5px 10px; /* Adds 5 pixels of padding on the top and bottom, and 10 pixels on the left and right */
  background-color: #646D74;
}

/*
.foot-background{
  background-image: url("https://images.unsplash.com/photo-1453950357290-38ae7a99541f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2062&q=80");
  background-size: 100% 100%;
}


.text-center p-3{
  background-color: #646D74;
}
*/
.email-container {
  /* From https://css.glass */
 background: rgba(255, 255, 255, 0.44);
 border-radius: 16px;
 box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
 backdrop-filter: blur(7.7px);
 -webkit-backdrop-filter: blur(7.7px);
 border: 1px solid rgba(255, 255, 255, 1);
 padding: 20px;
}
